import produce from 'immer';
import { IApp } from '@interfaces/pages/_app';
import { IAction } from '@interfaces/logic/redux';
import { ACTION_TYPES } from '@actions/actions_types';
import { MAX_PARTICIPANTS_SHOW, MEETING_STATE_IDLE } from '@constants';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

interface ICallArgs {
    pinnedUserId: string;
    activeSpeakerId: string;
    waitingParticipantsRoom: IApp.IParticipantEvent[];
    mute: boolean;
    userId: string;
    muted: boolean;
    waitingParticipantRoom: IApp.IParticipantEvent;
    waitingParticipantRoomId: string;
}

const INITIAL_STATE: IApp.ICall = {
    callObject: null,
    meetingState: MEETING_STATE_IDLE,
    participants: [],
    roomId: null,
    receptionRoom: false,
    pinnedUsersIds: null,
    screenShareUsersIds: null,
    networkQuality: null,
    activeSpeakersIdsList: null,
    activeSpeakerId: null,
    defaultMuted: false,
    tchatMuted: false,
    defaultNoCam: false,
    waitingParticipantsRoom: []
};

export const CallReducer = (
    state = INITIAL_STATE,
    action: IAction<IApp.ICall & ICallArgs>
) => {
    switch (action.type) {
        case ACTION_TYPES.Call.setCallObject:
            return produce(state, draft => {
                if (action.payload) {
                    draft.callObject = action.payload.callObject;
                }
            });
        case ACTION_TYPES.Call.resetState:
            return { ...INITIAL_STATE };
        case ACTION_TYPES.Call.setMeetingState:
            return produce(state, draft => {
                if (action.payload) {
                    draft.meetingState = action.payload.meetingState;
                }
            });
        case ACTION_TYPES.Call.setParticipants:
            return produce(state, draft => {
                if (action.payload) {
                    const tabDeja: string[] = [];
                    const tmpPart: IApp.IParticipant[] = [];
                    Object.entries(action.payload.participants).forEach(
                        ([id, callItem]: any) => {
                            const current = state.participants.filter(
                                item => item.userId == callItem.user_id
                            )[0];
                            if (tabDeja.indexOf(callItem.user_id) === -1) {
                                tabDeja.push(callItem.user_id);
                                tmpPart.push({
                                    userName: callItem.user_name,
                                    userId: callItem.user_id,
                                    local: id == 'local',
                                    video: callItem.video,
                                    audio: callItem.audio,
                                    pinned:
                                        state.pinnedUsersIds !== null &&
                                        state.pinnedUsersIds.includes(
                                            callItem.user_id
                                        ),
                                    screen: callItem.screen,
                                    owner: callItem.owner,
                                    topic:
                                        publicRuntimeConfig.LIEN_MERCURE +
                                        'users/' +
                                        callItem.user_id,
                                    mutedTchat: current?.mutedTchat
                                        ? current?.mutedTchat
                                        : false
                                });
                            }
                        }
                    );
                    draft.participants = tmpPart;
                    // On nettoie les pinned
                    if (state.pinnedUsersIds !== null)
                        draft.pinnedUsersIds = state.pinnedUsersIds.filter(
                            x => {
                                let found = false;
                                tmpPart.forEach(item => {
                                    if (item.userId == x) found = true;
                                });
                                return found;
                            }
                        );
                    // On nettoie les activespeakers
                    if (state.activeSpeakersIdsList !== null)
                        draft.activeSpeakersIdsList = state.activeSpeakersIdsList.filter(
                            x => {
                                let found = false;
                                tmpPart.forEach(item => {
                                    if (item.userId == x) found = true;
                                });
                                return found;
                            }
                        );
                    // On nettoie les sharescreen
                    if (state.screenShareUsersIds !== null)
                        draft.screenShareUsersIds = state.screenShareUsersIds.filter(
                            x => {
                                let found = false;
                                tmpPart.forEach(item => {
                                    if (item.userId == x) found = true;
                                });
                                return found;
                            }
                        );
                }
            });
        case ACTION_TYPES.Call.setMutedParticipant:
            return produce(state, draft => {
                if (action.payload) {
                    const tmpPart: IApp.IParticipant[] = [];
                    Object.entries(state.participants).forEach(
                        ([id, callItem]: any) => {
                            const participant: IApp.IParticipant =
                                callItem.userId == action.payload?.userId
                                    ? {
                                          userName: callItem.userName,
                                          userId: callItem.userId,
                                          local: id == 'local',
                                          video: callItem.video,
                                          audio: callItem.audio,
                                          pinned: callItem.pinned,
                                          screen: callItem.screen,
                                          owner: callItem.owner,
                                          mutedTchat: action.payload?.muted
                                      }
                                    : callItem;
                            tmpPart.push(participant);
                            draft.participants = tmpPart;
                        }
                    );
                }
            });

        case ACTION_TYPES.Call.setNetworkQuality:
            return produce(state, draft => {
                if (action.payload) {
                    draft.networkQuality = action.payload.networkQuality;
                }
            });
        case ACTION_TYPES.Call.setRoomId:
            return produce(state, draft => {
                if (action.payload) {
                    draft.roomId = action.payload.roomId;
                }
            });
        case ACTION_TYPES.Call.setReceptionRoom:
            return produce(state, draft => {
                if (action.payload) {
                    draft.receptionRoom = action.payload.receptionRoom;
                }
            });
        case ACTION_TYPES.Call.pinParticipant:
            return produce(state, draft => {
                if (action.payload) {
                    const tmpPart: IApp.IParticipant[] = [];
                    Object.entries(state.participants).forEach(
                        (callItem: any) => {
                            let pinned = false;
                            if (
                                action.payload &&
                                callItem[1].user_id ==
                                    action.payload.pinnedUserId &&
                                (state.pinnedUsersIds === null ||
                                    !state.pinnedUsersIds.includes(
                                        callItem[1].user_id
                                    ))
                            ) {
                                pinned = true;
                            }
                            tmpPart.push({ ...callItem[1], ...{ pinned } });
                        }
                    );
                    draft.participants = tmpPart;
                    // Si aucun utilisateur pin, on le pin
                    if (state.pinnedUsersIds === null)
                        draft.pinnedUsersIds = [action.payload.pinnedUserId];
                    // Sinon on vérifie si il était déjà pin
                    else if (
                        state.pinnedUsersIds.includes(
                            action.payload.pinnedUserId
                        )
                    ) {
                        draft.pinnedUsersIds = state.pinnedUsersIds.filter(
                            x =>
                                action.payload &&
                                x != action.payload.pinnedUserId
                        );
                        if (draft.pinnedUsersIds.length == 0)
                            draft.pinnedUsersIds = null;
                    }
                    // Si il n'était pas déjà pin
                    else {
                        draft.pinnedUsersIds = [action.payload.pinnedUserId];
                    }
                }
            });
        case ACTION_TYPES.Call.setScreenShare:
            return produce(state, draft => {
                if (action.payload) {
                    const tmpScreenShare: string[] = [];
                    Object.entries(action.payload.participants).forEach(
                        (participant: any) => {
                            if (participant[1].screen) {
                                tmpScreenShare.push(participant[1].user_id);
                            }
                        }
                    );
                    draft.pinnedUsersIds = state.pinnedUsersIds;
                    // Si d'anciens screenshare ne le sont plus, on les unpin
                    if (state.screenShareUsersIds !== null) {
                        state.screenShareUsersIds.forEach(item => {
                            if (
                                draft.pinnedUsersIds !== null &&
                                !tmpScreenShare.includes(item)
                            ) {
                                draft.pinnedUsersIds = draft.pinnedUsersIds.filter(
                                    x => x != item
                                );
                            }
                        });
                    }
                    // Si de nouveaux screens ont été trouvés, on force le pin
                    if (tmpScreenShare.length > 0) {
                        if (draft.pinnedUsersIds === null)
                            draft.pinnedUsersIds = [];
                        tmpScreenShare.forEach(item => {
                            if (
                                (state.pinnedUsersIds === null ||
                                    !state.pinnedUsersIds.includes(item)) &&
                                draft.pinnedUsersIds
                            ) {
                                draft.pinnedUsersIds.push(item);
                            }
                        });
                    }
                    if (tmpScreenShare.length > 0) {
                        if (state.pinnedUsersIds === null)
                            draft.pinnedUsersIds = [tmpScreenShare[0]];
                        else if (
                            !state.pinnedUsersIds.includes(tmpScreenShare[0])
                        )
                            draft.pinnedUsersIds = [
                                ...state.pinnedUsersIds,
                                tmpScreenShare[0]
                            ];
                    }

                    draft.screenShareUsersIds = tmpScreenShare;

                    if (
                        draft.pinnedUsersIds !== null &&
                        draft.pinnedUsersIds.length == 0
                    )
                        draft.pinnedUsersIds = null;
                }
            });
        case ACTION_TYPES.Call.setActiveSpeaker:
            return produce(state, draft => {
                // Si quelqu'un parle, on met à jour la liste des speakers
                if (action.payload && action.payload.activeSpeakerId !== null) {
                    draft.activeSpeakersIdsList = state.activeSpeakersIdsList;
                    if (draft.activeSpeakersIdsList === null)
                        draft.activeSpeakersIdsList = [];
                    // Si personne n'est pin, ou que le speaker n'est pas dans le tableau, ou est à un index > MAX_PARTICIPANTS_SHOW , on met à jour le tableau
                    const indexSpeaker = draft.activeSpeakersIdsList.indexOf(
                        action.payload.activeSpeakerId
                    );
                    if (
                        state.pinnedUsersIds === null ||
                        state.pinnedUsersIds.length == 0 ||
                        indexSpeaker == -1 ||
                        indexSpeaker >= MAX_PARTICIPANTS_SHOW
                    ) {
                        draft.activeSpeakersIdsList = draft.activeSpeakersIdsList.filter(
                            x =>
                                !action.payload ||
                                x != action.payload.activeSpeakerId
                        );

                        draft.activeSpeakersIdsList.unshift(
                            action.payload.activeSpeakerId
                        );
                    }
                }
                if (action.payload) {
                    draft.activeSpeakerId = action.payload.activeSpeakerId;
                }
            });
        case ACTION_TYPES.Call.setDefaultMuted:
            return produce(state, draft => {
                if (action.payload)
                    draft.defaultMuted = action.payload.defaultMuted;
            });
        case ACTION_TYPES.Call.toggleDefaultMuted:
            return produce(state, draft => {
                draft.defaultMuted = !state.defaultMuted;
            });
        case ACTION_TYPES.Call.toggleDefaultNoCam:
            return produce(state, draft => {
                draft.defaultNoCam = !state.defaultNoCam;
            });
        case ACTION_TYPES.Call.setDefaultNoCam:
            return produce(state, draft => {
                if (action.payload)
                    draft.defaultNoCam = action.payload.defaultNoCam;
            });
        case ACTION_TYPES.Call.setWaitingParticipantsRoom:
            return produce(state, draft => {
                if (action.payload) {
                    draft.waitingParticipantsRoom =
                        action.payload.waitingParticipantsRoom;
                }
            });
        case ACTION_TYPES.Call.addWaitingParticipantsRoom:
            return produce(state, draft => {
                if (
                    action.payload &&
                    state.waitingParticipantsRoom.findIndex(
                        participant =>
                            action.payload &&
                            participant.topic ===
                                action.payload.waitingParticipantRoom.topic
                    ) === -1
                ) {
                    draft.waitingParticipantsRoom = [
                        ...state.waitingParticipantsRoom,
                        action.payload.waitingParticipantRoom
                    ];
                }
            });
        case ACTION_TYPES.Call.removeWaitingParticipantsRoom:
            return produce(state, draft => {
                if (action.payload) {
                    draft.waitingParticipantsRoom = state.waitingParticipantsRoom.filter(
                        participant =>
                            action.payload &&
                            participant.userId !==
                                action.payload.waitingParticipantRoomId
                    );
                }
            });
        case ACTION_TYPES.Call.tchatMute:
            return produce(state, draft => {
                if (action.payload) {
                    draft.tchatMuted = action.payload.mute;
                }
            });

        default:
            return state;
    }
};
