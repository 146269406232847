import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import Reducers from '@reducers';

const store = (initialState: any) => {
    return createStore(
        Reducers,
        initialState,
        composeWithDevTools(applyMiddleware(thunkMiddleware))
    );
};

export default store;
