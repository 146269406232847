import React, { useEffect } from 'react';
import { colors, bp } from '@components/_struct/variables';
import styled from 'styled-components';
import Button from '@components/_common/Button';
import { FallbackProps } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import Appsignal from '@appsignal/javascript';
import getConfig from 'next/config';
import { useMSTranslation } from '@utils/useMSTranslation';
const { publicRuntimeConfig } = getConfig();

const Picto = styled.img`
    max-width: 100%;
    width: 80%;
    display: block;
    margin: auto;
    max-height: 100%;
`;

const MainTitle = styled.h1`
    font-size: 50px;
    font-weight: bold;
    color: ${colors.main};
    margin-bottom: 20px;
    @media (${bp.md}) {
        text-align: center;
        font-size: 24px;
    }
`;

const ErrorName = styled.div`
    border: 2px solid rgba(10, 14, 39, 0.1);
    border-radius: 6px;
    padding: 10px 20px;
    display: inline-block;
    font-size: 18px;
`;

const LayoutWrapper = styled.div`
    display: flex;
    height: 100%;
    position: relative;
    padding: 20px;
    background: #f0f1f5;
    @media (${bp.sm}) {
        justify-content: start;
        height: auto;
        min-height: 100%;
        padding: 40px 20px;
        display: block;
    }
`;

const appsignal = new Appsignal({
    key: '05c85039-3026-413e-a751-2a5591407173',
    revision: '1.0.1'
});

const ErrorBoundaries = (props: FallbackProps) => {
    const { error } = props;
    const { __ } = useMSTranslation(['struct']);

    const callObject = useSelector((state: IStore) => state.call.callObject);

    useEffect(() => {
        if (!callObject) return;
        else callObject.leave();
    }, [callObject]);

    console.log(error);
    if (error && publicRuntimeConfig.NODE_ENV == 'production')
        appsignal.sendError(error);

    return (
        <LayoutWrapper>
            <div className='md:w-1/2 flex'>
                <div className='m-auto w-full lg:w-3/4'>
                    <MainTitle>
                        {__('Aïe. Viseet vient de crash', 'struct')}.
                    </MainTitle>
                    <div className='text-lg lg:text-xl opacity-75 leading-normal text-center md:text-left'>
                        {__(
                            "Vous pouvez directement rafraîchir votre page pour vous reconnecter, ou si le cœur vous en dit, nous aider à corriger ce problème en nous envoyant le rapport d'erreur suivant",
                            'struct'
                        )}{' '}
                        :
                    </div>
                    {error && (
                        <div className='my-2'>
                            <ErrorName>
                                {error.name} : {error.message}
                            </ErrorName>
                        </div>
                    )}
                    <div className='mt-2 md:mt-4 text-center md:text-left'>
                        <Button
                            variant='outline'
                            onClick={() => window.location.reload()}
                        >
                            {__('Rafraîchir la page', 'struct')}
                        </Button>
                    </div>
                </div>
            </div>
            <div className='md:w-1/2 mt-3 md:mt-0 flex'>
                <Picto src='/images/sad-cat.svg' alt='' />
            </div>
        </LayoutWrapper>
    );
};

export default ErrorBoundaries;
