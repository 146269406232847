import { library as fontawesome } from '@fortawesome/fontawesome-svg-core';
import {
    faUsers as fasUsers,
    faSignIn as fasSignIn,
    faSignOut as fasSignOut,
    faUserPlus as fasUserPlus,
    faMicrophone as fasMicrophone,
    faWebcam as fasWebcam,
    faCog as fasCog,
    faMicrophoneSlash as fasMicrophoneSlash,
    faWebcamSlash as fasWebcamSlash,
    faCaretLeft as fasCaretLeft,
    faCaretRight as fasCaretRight,
    faCaretDown as fasCaretDown,
    faChevronUp as fasChevronUp,
    faThumbtack as fasThumbtack,
    faDesktop as fasDesktop,
    faExclamationTriangle as fasExclamationTriangle,
    faCheckCircle as fasCheckCircle,
    faChevronRight as fasChevronRight,
    faChevronLeft as fasChevronLeft,
    faEllipsisV as fasEllipsisV,
    faTimes as fasTimes,
    faChevronDown as fasChevronDown,
    faUserClock as fasUserClock,
    faCalendarAlt as fasCalendarAlt,
    faHourglassHalf as fasHourglassHalf,
    faSearch as fasSearch,
    faClock as fasClock,
    faCheck as fasCheck,
    faHandPaper as fasHandPaper,
    faCloudUpload as fasCloudUpload,
    faBars as fasBars,
    faComments as fasComments,
    faPaperPlane as fasPaperPlane,
    faLock as fasLock,
    faSpinnerThird as fasSpinnerThird,
    faThumbsUp as fasThumbsUp,
    faThumbsDown as fasThumbsDown,
    faGlobeEurope as fasGlobeEurope
} from '@fortawesome/pro-solid-svg-icons';
import {
    faUsers,
    faSignOut,
    faUserPlus,
    faMicrophone,
    faWebcam,
    faCog,
    faMicrophoneSlash,
    faWebcamSlash,
    faCaretLeft,
    faCaretRight,
    faCaretDown,
    faChevronUp,
    faThumbtack,
    faDesktop,
    faSignIn,
    faExclamationTriangle,
    faCheckCircle,
    faChevronRight,
    faChevronLeft,
    faEllipsisV,
    faTimes,
    faCalendarAlt,
    faChevronDown,
    faUserClock,
    faClock,
    faSearch,
    faHourglassHalf,
    faCheck,
    faHandPaper,
    faCloudUpload,
    faBars,
    faComments,
    faPaperPlane,
    faLock,
    faSpinnerThird,
    faThumbsUp,
    faThumbsDown,
    faGlobeEurope
} from '@fortawesome/pro-regular-svg-icons';
import {
    faUsers as fadUsers,
    faCalendarStar as fadCalendarStar,
    faMousePointer as fadMousePointer,
    faHistory as fadHistory
} from '@fortawesome/pro-duotone-svg-icons';

const initIconLibrary = () => {
    fontawesome.add(
        //Duotone
        fadUsers,
        fadCalendarStar,
        fadMousePointer,
        fadHistory,
        // Reste
        faSpinnerThird,
        fasSpinnerThird,
        faUsers,
        fasUsers,
        faSignIn,
        fasSignIn,
        faSignOut,
        fasSignOut,
        faUserPlus,
        fasUserPlus,
        faMicrophone,
        fasMicrophone,
        faMicrophoneSlash,
        fasMicrophoneSlash,
        faWebcam,
        fasWebcam,
        faWebcamSlash,
        fasWebcamSlash,
        faCog,
        fasCog,
        faCaretLeft,
        fasCaretLeft,
        faCaretRight,
        fasCaretRight,
        faCaretDown,
        fasCaretDown,
        faChevronUp,
        fasChevronUp,
        faChevronDown,
        fasChevronDown,
        faThumbtack,
        fasThumbtack,
        faDesktop,
        fasDesktop,
        faExclamationTriangle,
        fasExclamationTriangle,
        faCheckCircle,
        fasCheckCircle,
        faChevronRight,
        fasChevronRight,
        faChevronLeft,
        fasChevronLeft,
        faEllipsisV,
        fasEllipsisV,
        faTimes,
        fasTimes,
        faCalendarAlt,
        fasCalendarAlt,
        faClock,
        fasClock,
        faUserClock,
        fasUserClock,
        faCheck,
        fasCheck,
        faHourglassHalf,
        fasHourglassHalf,
        faHandPaper,
        faSearch,
        fasSearch,
        fasHandPaper,
        faCloudUpload,
        fasCloudUpload,
        faBars,
        fasBars,
        faComments,
        fasComments,
        faPaperPlane,
        fasPaperPlane,
        faLock,
        fasLock,
        faThumbsUp,
        fasThumbsUp,
        faThumbsDown,
        fasThumbsDown,
        faGlobeEurope,
        fasGlobeEurope
    );
};

export default initIconLibrary;
