import { IApp } from '@interfaces/pages/_app';
import { IAction } from '@interfaces/logic/redux';
import { ACTION_TYPES } from '@actions/actions_types';
import produce from 'immer';

const INITIAL_STATE: IApp.IErrors = {
    messages: [],
    messagesPage: []
};

export const ErrorsReducer = (
    state = INITIAL_STATE,
    action: IAction<IApp.IError>
) => {
    switch (action.type) {
        case ACTION_TYPES.Errors.addError:
            return produce(state, draft => {
                if (action.payload) {
                    draft.messages.push(action.payload.message);
                }
            });
        case ACTION_TYPES.Errors.addErrorPage:
            return produce(state, draft => {
                if (action.payload) {
                    draft.messagesPage.push(action.payload.message);
                }
            });
        case ACTION_TYPES.Errors.reset:
            return produce(state, draft => {
                draft.messages = [];
            });
        case ACTION_TYPES.Errors.resetPage:
            return produce(state, draft => {
                draft.messagesPage = [];
            });
        default:
            return state;
    }
};
