import { IApp } from '@interfaces/pages/_app';
import { IAction } from '@interfaces/logic/redux';
import { ACTION_TYPES } from '@actions/actions_types';
import produce from 'immer';
import { SIGNUP_STATUS_IDLE } from '@components/Join/Signup/SignupStatus';

const INITIAL_STATE: IApp.IPageJoin = {
    waitingShortToken: true,
    waitingMeetingToken: true,
    statusSignup: SIGNUP_STATUS_IDLE,
    emailSignup: '',
    mailSended: false
};

export const PageJoinReducer = (
    state = INITIAL_STATE,
    action: IAction<IApp.IPageJoin>
) => {
    switch (action.type) {
        case ACTION_TYPES.Page.Join.setWaitingShortToken:
            return produce(state, draft => {
                if (action.payload)
                    draft.waitingShortToken = action.payload.waitingShortToken;
            });
        case ACTION_TYPES.Page.Join.setWaitingMeetingToken:
            return produce(state, draft => {
                if (action.payload)
                    draft.waitingMeetingToken =
                        action.payload.waitingMeetingToken;
            });
        case ACTION_TYPES.Page.Join.setStatusSignup:
            return produce(state, draft => {
                if (action.payload)
                    draft.statusSignup = action.payload.statusSignup;
            });
        case ACTION_TYPES.Page.Join.setEmailSignup:
            return produce(state, draft => {
                if (action.payload)
                    draft.emailSignup = action.payload.emailSignup;
            });
        case ACTION_TYPES.Page.Join.mailSended:
            return produce(state, draft => {
                draft.mailSended = true;
            });
        default:
            return state;
    }
};
