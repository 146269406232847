import { IApp } from '@interfaces/pages/_app';
import { IAction } from '@interfaces/logic/redux';
import { ACTION_TYPES } from '@actions/actions_types';
import produce from 'immer';

interface IUIProps {
    statut: boolean;
    nbr: number;
    owner: boolean;
    usedShortToken: boolean;
    userId: string;
}

const INITIAL_STATE: IApp.IUI = {
    showListUsers: false,
    showWaitingList: false,
    showEventInfos: false,
    showRoom: false,
    showAddUser: false,
    showChat: false,
    selectedParticipants: [],
    selectedWaitingParticipants: [],
    selectedIntervenants: [],
    selectedVisiteurs: [],
    selectedWaitingDispatch: [],
    noCam: false,
    noMic: false,
    waitingRoom: '',
    nbrUnreaded: 0,
    drawerCandidat: {
        isOpen: false,
        userId: null
    },
    tmpRoomOwner: false,
    fiche: null,
    usedShortToken: false
};

export const UIReducer = (
    state = INITIAL_STATE,
    action: IAction<IApp.IUI & IUIProps>
) => {
    switch (action.type) {
        case ACTION_TYPES.UI.toggleShowListUsers:
            return produce(state, draft => {
                draft.showListUsers = !state.showListUsers;
                draft.showWaitingList = false;
                draft.showAddUser = false;
                draft.showRoom = false;
                draft.showChat = false;
            });
        case ACTION_TYPES.UI.toggleShowWaitingList:
            return produce(state, draft => {
                draft.showWaitingList = !state.showWaitingList;
                draft.showListUsers = false;
                draft.showAddUser = false;
                draft.showRoom = false;
                draft.showChat = false;
            });
        case ACTION_TYPES.UI.toggleShowEventInfos:
            return produce(state, draft => {
                draft.showEventInfos = !state.showEventInfos;
                draft.showRoom = false;
            });
        case ACTION_TYPES.UI.toggleShowRoom:
            return produce(state, draft => {
                draft.showRoom = !state.showRoom;
                draft.showListUsers = false;
                draft.showWaitingList = false;
                draft.showAddUser = false;
                draft.showEventInfos = false;
                draft.showChat = false;
            });
        case ACTION_TYPES.UI.toggleAddUser:
            return produce(state, draft => {
                draft.showAddUser = !state.showAddUser;
                draft.showListUsers = false;
                draft.showWaitingList = false;
                draft.showRoom = false;
                draft.showChat = false;
            });
        case ACTION_TYPES.UI.toggleChat:
            return produce(state, draft => {
                draft.showChat = !state.showChat;
                draft.showAddUser = false;
                draft.showListUsers = false;
                draft.showWaitingList = false;
                draft.showRoom = false;
            });
        case ACTION_TYPES.UI.setNoMic:
            return produce(state, draft => {
                if (action.payload) draft.noMic = action.payload.statut;
            });
        case ACTION_TYPES.UI.setNoCam:
            return produce(state, draft => {
                if (action.payload) draft.noCam = action.payload.statut;
            });
        case ACTION_TYPES.UI.selectedParticipants:
            return produce(state, draft => {
                if (action.payload) {
                    draft.selectedParticipants =
                        action.payload.selectedParticipants;
                }
            });
        case ACTION_TYPES.UI.selectedWaitingParticipants:
            return produce(state, draft => {
                if (action.payload) {
                    draft.selectedWaitingParticipants =
                        action.payload.selectedWaitingParticipants;
                }
            });
        case ACTION_TYPES.UI.selectedIntervenants:
            return produce(state, draft => {
                if (action.payload) {
                    draft.selectedIntervenants =
                        action.payload.selectedIntervenants;
                }
            });
        case ACTION_TYPES.UI.selectedVisiteurs:
            return produce(state, draft => {
                if (action.payload) {
                    draft.selectedVisiteurs = action.payload.selectedVisiteurs;
                }
            });
        case ACTION_TYPES.UI.selectedWaitingDispatch:
            return produce(state, draft => {
                if (action.payload) {
                    draft.selectedWaitingDispatch =
                        action.payload.selectedWaitingDispatch;
                }
            });
        case ACTION_TYPES.UI.removeSelectedWaitingDispatch:
            return produce(state, draft => {
                if (action.payload) {
                    draft.selectedWaitingDispatch = state.selectedWaitingDispatch.filter(
                        item => action.payload && item != action.payload.userId
                    );
                }
            });
        case ACTION_TYPES.UI.setWaitingRoom:
            return produce(state, draft => {
                if (action.payload)
                    draft.waitingRoom = action.payload.waitingRoom;
            });
        case ACTION_TYPES.UI.setUnreadedMsgCount:
            return produce(state, draft => {
                if (action.payload) {
                    draft.nbrUnreaded = action.payload.nbr;
                }
            });
        case ACTION_TYPES.UI.incrementUnreadedMsgCount:
            return produce(state, draft => {
                if (!state.showChat) draft.nbrUnreaded = state.nbrUnreaded + 1;
            });
        case ACTION_TYPES.UI.openDrawerCandidat:
            return produce(state, draft => {
                if (action.payload)
                    draft.drawerCandidat = action.payload.drawerCandidat;
            });
        case ACTION_TYPES.UI.closeDrawerCandidat:
            return produce(state, draft => {
                draft.drawerCandidat = { isOpen: false, userId: null };
            });
        case ACTION_TYPES.UI.setTmpRoomOwner:
            return produce(state, draft => {
                if (action.payload) {
                    draft.tmpRoomOwner = action.payload.owner;
                }
            });
        case ACTION_TYPES.UI.setDrawerCandidatData:
            return produce(state, draft => {
                if (action.payload) draft.fiche = action.payload.fiche;
            });
        case ACTION_TYPES.UI.setUsedShortToken:
            return produce(state, draft => {
                if (action.payload)
                    draft.usedShortToken = action.payload.usedShortToken;
            });
        case ACTION_TYPES.UI.resetState:
            return produce(state, draft => {
                draft.showListUsers = false;
                draft.showWaitingList = false;
                draft.showEventInfos = false;
                draft.showRoom = false;
                draft.showAddUser = false;
                draft.showChat = false;
                draft.selectedParticipants = [];
                draft.selectedWaitingParticipants = [];
                draft.selectedIntervenants = [];
                draft.selectedVisiteurs = [];
                draft.selectedWaitingDispatch = [];
                draft.noCam = false;
                draft.noMic = false;
                draft.waitingRoom = '';
                draft.nbrUnreaded = 0;
                draft.drawerCandidat = {
                    isOpen: false,
                    userId: null
                };
                draft.fiche = null;
                draft.usedShortToken = false;
            });
        default:
            return state;
    }
};
