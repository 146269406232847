import produce from 'immer';
import { ACTION_TYPES } from '@actions/actions_types';
import { IAction } from '@interfaces/logic/redux';
import { IApp } from '@interfaces/pages/_app';

interface IDataPayload {
    event: IEvent;
    participantsConnectedEvent: IApp.IParticipantEvent[];
    participantsReception: IApp.IParticipantEvent[];
    messageRoom: IApp.IMessageRoom;
    mercureTokenEvent: string;
    lastEventId: string;
    msgId: string;
    userId: string;
    muted: boolean;
    notes: INoteApi[];
    participantId: string;
    participant: IApp.IParticipantEvent;
    roomId: string;
    nbConnected: number;
    nbWaiting: number;
}
const INITIAL_STATE: IApp.IData = {
    event: null,
    participantsConnectedEvent: [],
    mercureTokenEvent: '',
    lastEventId: '',
    lastEventIdReception: '',
    messagesRoom: [],
    mutedParticipants: [],
    notes: [],
    participantsReception: [],
    nbParticipantsConnectedRooms: []
};

export const DataReducer = (
    state = INITIAL_STATE,
    action: IAction<IDataPayload>
) => {
    switch (action.type) {
        case ACTION_TYPES.Data.setEvent:
            return produce(state, draft => {
                if (action.payload) {
                    draft.event = action.payload.event;
                }
            });
        case ACTION_TYPES.Data.setParticipantsConnected:
            return produce(state, draft => {
                if (action.payload) {
                    draft.participantsConnectedEvent =
                        action.payload.participantsConnectedEvent;
                }
            });
        case ACTION_TYPES.Data.removeParticipantsConnected:
            return produce(state, draft => {
                if (action.payload) {
                    draft.participantsConnectedEvent = state.participantsConnectedEvent.filter(
                        participant =>
                            action.payload &&
                            participant.userId !== action.payload.participantId
                    );
                }
            });
        case ACTION_TYPES.Data.addParticipantsConnected:
            return produce(state, draft => {
                if (
                    action.payload &&
                    state.participantsConnectedEvent.findIndex(
                        participant =>
                            action.payload &&
                            participant.topic ===
                                action.payload.participant.topic
                    ) === -1
                ) {
                    draft.participantsConnectedEvent = [
                        ...state.participantsConnectedEvent,
                        action.payload.participant
                    ];
                }
            });

        case ACTION_TYPES.Data.removeParticipantReception:
            return produce(state, draft => {
                if (action.payload) {
                    draft.participantsReception = state.participantsReception.filter(
                        participant =>
                            action.payload &&
                            participant.userId !== action.payload.participantId
                    );
                }
            });
        case ACTION_TYPES.Data.initiateParticipantReception:
            return produce(state, draft => {
                if (action.payload) {
                    draft.participantsReception = state.participantsReception.map(
                        participant => {
                            if (
                                action.payload &&
                                action.payload.userId === participant.userId
                            )
                                return {
                                    ...participant,
                                    roomInitiated: new Date().getTime()
                                };
                            else return participant;
                        }
                    );
                }
            });
        case ACTION_TYPES.Data.removeInitiateParticipantReception:
            return produce(state, draft => {
                if (action.payload) {
                    draft.participantsReception = state.participantsReception.map(
                        participant => {
                            if (
                                action.payload &&
                                action.payload.userId === participant.userId
                            )
                                return {
                                    ...participant,
                                    roomInitiated: undefined
                                };
                            else return participant;
                        }
                    );
                }
            });
        case ACTION_TYPES.Data.addParticipantReception:
            return produce(state, draft => {
                if (
                    action.payload &&
                    state.participantsReception.findIndex(
                        participant =>
                            action.payload &&
                            participant.topic ===
                                action.payload.participant.topic
                    ) === -1
                ) {
                    draft.participantsReception = [
                        ...state.participantsReception,
                        action.payload.participant
                    ];
                }
            });
        case ACTION_TYPES.Data.setParticipantsReception:
            return produce(state, draft => {
                if (action.payload) {
                    draft.participantsReception =
                        action.payload.participantsReception;
                }
            });
        case ACTION_TYPES.Data.setMessage:
            return produce(state, draft => {
                if (action.payload) {
                    draft.messagesRoom = [
                        ...state.messagesRoom,
                        action.payload.messageRoom
                    ];
                }
            });
        case ACTION_TYPES.Data.resetMessages:
            return produce(state, draft => {
                draft.messagesRoom = [];
            });
        case ACTION_TYPES.Data.deleteMessage:
            return produce(state, draft => {
                if (action.payload && action.payload.msgId) {
                    draft.messagesRoom = state.messagesRoom.filter(
                        item => item.msgId !== action?.payload?.msgId
                    );
                }
            });
        case ACTION_TYPES.Data.hideMessage:
            return produce(state, draft => {
                if (action.payload && action.payload.msgId) {
                    draft.messagesRoom = state.messagesRoom.map(item => {
                        if (item.msgId == action?.payload?.msgId) {
                            return {
                                msgId: item.msgId,
                                msg: item.msg,
                                username: item.username,
                                userId: item.userId,
                                date: item.date,
                                hide: true
                            };
                        }
                        return item;
                    });
                }
            });
        case ACTION_TYPES.Data.unHideMessage:
            return produce(state, draft => {
                if (action.payload && action.payload.msgId) {
                    draft.messagesRoom = state.messagesRoom.map(item => {
                        if (item.msgId == action?.payload?.msgId) {
                            return {
                                msgId: item.msgId,
                                msg: item.msg,
                                username: item.username,
                                userId: item.userId,
                                date: item.date,
                                hide: false
                            };
                        }
                        return item;
                    });
                }
            });
        case ACTION_TYPES.Data.setMercureTokenEvent:
            return produce(state, draft => {
                if (action.payload) {
                    draft.mercureTokenEvent = action.payload.mercureTokenEvent;
                }
            });
        case ACTION_TYPES.Data.setLastEventId:
            return produce(state, draft => {
                if (action.payload) {
                    draft.lastEventId = action.payload.lastEventId;
                }
            });
        case ACTION_TYPES.Data.setLastEventIdReception:
            return produce(state, draft => {
                if (action.payload) {
                    draft.lastEventIdReception = action.payload.lastEventId;
                }
            });
        case ACTION_TYPES.Data.setNotes:
            return produce(state, draft => {
                if (action.payload) {
                    draft.notes = action.payload.notes;
                }
            });
        case ACTION_TYPES.Data.setNbParticipantsRoom:
            return produce(state, draft => {
                if (action.payload) {
                    // Si la room existe on l'update
                    if (
                        state.nbParticipantsConnectedRooms.find(room => {
                            return (
                                action.payload &&
                                room.roomId == action.payload.roomId
                            );
                        })
                    ) {
                        draft.nbParticipantsConnectedRooms = state.nbParticipantsConnectedRooms.map(
                            room => {
                                if (
                                    action.payload &&
                                    room.roomId == action.payload.roomId
                                )
                                    return {
                                        roomId: room.roomId,
                                        nbConnected: action.payload.nbConnected,
                                        nbWaiting: room.nbWaiting
                                    };
                                else return room;
                            }
                        );
                    }
                    // Sinon on la créé
                    else {
                        draft.nbParticipantsConnectedRooms = [
                            ...state.nbParticipantsConnectedRooms,
                            {
                                roomId: action.payload.roomId,
                                nbConnected: action.payload.nbConnected,
                                nbWaiting: 0
                            }
                        ];
                    }
                }
            });
        case ACTION_TYPES.Data.setNbParticipantsWaitingRoom:
            return produce(state, draft => {
                if (action.payload) {
                    // Si la room existe on l'update
                    if (
                        state.nbParticipantsConnectedRooms.find(room => {
                            return (
                                action.payload &&
                                room.roomId == action.payload.roomId
                            );
                        })
                    ) {
                        draft.nbParticipantsConnectedRooms = state.nbParticipantsConnectedRooms.map(
                            room => {
                                if (
                                    action.payload &&
                                    room.roomId == action.payload.roomId
                                )
                                    return {
                                        roomId: room.roomId,
                                        nbConnected: room.nbConnected,
                                        nbWaiting: action.payload.nbWaiting
                                    };
                                else return room;
                            }
                        );
                    }
                    // Sinon on la créé
                    else {
                        draft.nbParticipantsConnectedRooms = [
                            ...state.nbParticipantsConnectedRooms,
                            {
                                roomId: action.payload.roomId,
                                nbConnected: 0,
                                nbWaiting: action.payload.nbWaiting
                            }
                        ];
                    }
                }
            });
        default:
            return state;
    }
};
