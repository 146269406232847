import { IApp } from '@interfaces/pages/_app';
import { IAction } from '@interfaces/logic/redux';
import { ACTION_TYPES } from '@actions/actions_types';
import produce from 'immer';

const INITIAL_STATE: IApp.Config = {
    configDevicesStep: false,
    needReception: false,
    devices: null
};

export const ConfigReducer = (
    state = INITIAL_STATE,
    action: IAction<IApp.Config>
) => {
    switch (action.type) {
        case ACTION_TYPES.Config.setConfigDevicesStep:
            return produce(state, draft => {
                if (action.payload)
                    draft.configDevicesStep = action.payload.configDevicesStep;
            });
        case ACTION_TYPES.Config.setDevices:
            return produce(state, draft => {
                if (action.payload) {
                    draft.devices = action.payload.devices;
                }
            });
        case ACTION_TYPES.Config.setNeedReception:
            return produce(state, draft => {
                if (action.payload) {
                    draft.needReception = action.payload.needReception;
                }
            });
        default:
            return state;
    }
};
