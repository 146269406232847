import { combineReducers } from 'redux';

import { ErrorsReducer } from '@reducers/errors_reducer';
import { CallReducer } from '@reducers/call_reducer';
import { UIReducer } from '@reducers/ui_reducer';
import { AuthReducer } from '@reducers/auth_reducer';
import { PageJoinReducer } from '@reducers/pages/join_reducer';
import { DataReducer } from '@reducers/data_reducer';
import { ConfigReducer } from '@reducers/config_reducer';
import { LoadingReducer } from '@reducers/loading_reducer';

export default combineReducers({
    errors: ErrorsReducer,
    call: CallReducer,
    UI: UIReducer,
    Config: ConfigReducer,
    Auth: AuthReducer,
    Data: DataReducer,
    PageJoin: PageJoinReducer,
    Loading: LoadingReducer
});
