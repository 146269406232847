import produce from 'immer';
import { ACTION_TYPES } from '@actions/actions_types';
import { IAction } from '@interfaces/logic/redux';
import { IAuth, IAuthPayload } from '@interfaces/logic/auth';

const INITIAL_STATE: IAuth = {
    ctoken: null,
    user: null,
    meetingToken: null,
    fiche: null
};

export const AuthReducer = (
    state = INITIAL_STATE,
    action: IAction<IAuthPayload>
) => {
    switch (action.type) {
        case ACTION_TYPES.Auth.setClientToken:
            return produce(state, draft => {
                if (action.payload) {
                    draft.ctoken = action.payload.token;
                }
            });
        case ACTION_TYPES.Auth.setUser:
            return produce(state, draft => {
                if (action.payload) {
                    draft.user = action.payload.user;
                }
            });
        case ACTION_TYPES.Auth.setMeetingToken:
            return produce(state, draft => {
                if (action.payload) {
                    draft.meetingToken = action.payload.meetingToken;
                }
            });
        case ACTION_TYPES.Auth.setFiche:
            return produce(state, draft => {
                if (action.payload) {
                    let cpt = 0;
                    const tab_fields: any = [];
                    if (action.payload?.formData.email) {
                        tab_fields.push({
                            name: 'Email',
                            values: action.payload?.formData.email
                        });
                    }
                    action.payload.fields.forEach((element: any) => {
                        if (
                            element.field !== 'email' &&
                            element.field !== 'file'
                        )
                            tab_fields.push({
                                name: element.labelfield,
                                values: action.payload?.formData['item' + cpt]
                            });
                        if (element.field == 'file') {
                            const file = action.payload?.files.filter(
                                (item: any) =>
                                    item.key == 'item' + cpt + '_file'
                            )[0];
                            if (file)
                                tab_fields.push({
                                    name: element.labelfield + '_file',
                                    labelField: element.labelfield,
                                    values: file.link,
                                    fileName: file.fileName
                                });
                        }
                        cpt++;
                    });
                    draft.fiche = tab_fields;
                }
            });

        default:
            return state;
    }
};
