import React from 'react';
import App, { AppContext } from 'next/app';
import Head from 'next/head';

import { Provider } from 'react-redux';
import withRedux from 'next-redux-wrapper';

import store from '@logic/store';
import { IApp } from '@interfaces/pages/_app';
import { appWithTranslation } from '@utils/i18n';

/** CSS global de l'app **/
import '@components/_struct/css/normalize.css';
import 'simplebar/dist/simplebar.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale-subtle.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;
import '@components/_struct/css/global.css';
import '@components/_struct/css/utilities.css';

import initIconLibrary from '@components/_struct/fontawesome';
import Error from '@components/_common/Error';
import { ToastContainer } from 'react-toastify';
import { getTokensStorage } from '@utils/auth';
import { AuthActions } from '@actions/auth_actions';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaries from '@components/_struct/ErrorBoundaries';

initIconLibrary();

class CVApp extends App<IApp.IProps> {
    static async getInitialProps({ Component, ctx }: AppContext) {
        let pageProps: any = {};
        if (Component.getInitialProps)
            pageProps = await Component.getInitialProps(ctx);

        // On récupère le token où qu'il soit (localstorage ou cookies)
        const { ctoken } = getTokensStorage(ctx);

        if (ctoken) ctx.store.dispatch(AuthActions.setClientToken(ctoken));
        else {
            await ctx.store.dispatch(AuthActions.getClientToken(ctx));
        }

        return {
            pageProps
        };
    }

    render(): JSX.Element {
        const { Component, pageProps, store } = this.props;

        return (
            <>
                <Head>
                    <meta
                        name='viewport'
                        content='width=device-width,initial-scale=1,viewport-fit=cover,user-scalable=no'
                    />
                </Head>
                <Provider store={store}>
                    <Error />
                    <ErrorBoundary FallbackComponent={ErrorBoundaries}>
                        <Component {...pageProps} />
                    </ErrorBoundary>
                    <ToastContainer />
                </Provider>
            </>
        );
    }
}

export default withRedux(store)(appWithTranslation(CVApp));
