import produce from 'immer';
import { ACTION_TYPES } from '@actions/actions_types';
import { IAction } from '@interfaces/logic/redux';
import { IApp } from '@interfaces/pages/_app';

interface ILoadingPayload {
    actionLoading: string;
    textLoading: JSX.Element | null;
}

const INITIAL_STATE: IApp.ILoading = {
    actionLoading: [],
    textLoading: null
};

export const LoadingReducer = (
    state = INITIAL_STATE,
    action: IAction<ILoadingPayload>
) => {
    switch (action.type) {
        case ACTION_TYPES.Loading.start:
            return produce(state, draft => {
                if (action.payload) {
                    draft.actionLoading.push(action.payload.actionLoading);
                }
            });
        case ACTION_TYPES.Loading.stop:
            return produce(state, draft => {
                if (action.payload) {
                    for (let i = 0; i < draft.actionLoading.length; i++) {
                        if (
                            draft.actionLoading[i] ==
                            action.payload.actionLoading
                        ) {
                            draft.actionLoading.splice(i, 1);
                            i--;
                        }
                    }
                }
            });
        case ACTION_TYPES.Loading.setText:
            return produce(state, draft => {
                if (action.payload) {
                    draft.textLoading = action.payload.textLoading;
                }
            });
        default:
            return state;
    }
};
