import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import styled from 'styled-components';
import { ErrorsActions } from '@actions/errors_actions';

const ErrorCont = styled.div`
    background: red;
    text-align: center;
    padding: 10px;
`;

const Error: React.FC = () => {
    const errors = useSelector((state: IStore) => state.errors);
    const dispatch = useDispatch();

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        if (errors.messages.length != 0) {
            timer = setTimeout(() => dispatch(ErrorsActions.reset()), 10000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [errors, dispatch]);

    if (errors.messages.length == 0) {
        return null;
    }
    return (
        <ErrorCont>
            {errors.messages.map((msg, i) => {
                return <div key={i}>{msg}</div>;
            })}
        </ErrorCont>
    );
};

export default Error;
